import React, { useEffect, useState } from 'react';
import styles from './Top.module.css';
import * as Images from '../Constant/Constant';

function Top({onSelectMenu}){
    const[background,setBackground]= useState({
        backgroundImg: Images.TopDesktop
    })
    function SelectMenu(value){
        onSelectMenu(value)
    }
    useEffect(()=>{

        function handleResize() {
            if(window.innerWidth > 767){
                setBackground({backgroundImg:Images.TopDesktop})
            }else{
                setBackground({backgroundImg:Images.TopMobile})
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        
        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    },[]);

    return(
        <div className={styles.wrapperTop}>
            <img className={styles.background} src={background.backgroundImg} alt={'background'}  />
            <div className={styles.wrapperText}>
                <div className={styles.wrapperRight}>
                    <h2 className={styles.titleTop}>CONOCE ÁVALON</h2>
                    <p className={styles.textTop}>La naturaleza de la ciudad se construye a partir de edificaciones que motivan su estilo.</p>
                </div>
                <div className={styles.wrapperLeft}>
                    <div className={`${styles.TextCommon} ${styles.disenio}`}>
                        <div className={styles.title}>
                            <span></span>
                            <img src={Images.DisenioSvg} alt={'menu-diseño'}/>
                            <span></span>
                        </div>
                        <div className={styles.text}>
                            <p>La naturaleza de la ciudad se construye a partir de edificaciones que motivan su estilo.Espectacular y sofisiticado proyecto de usos mixtos compuesto por una elegante torre residencial y una torre de oficinas.</p>
                            <p>El diseño arquitectónico único  fué concebido con un especial enfoque en la persona y su interacción con la naturaleza, armoniza con el entorno de cada área. Ávalon es un proyecto atemporal y tan atractivo como funcional. El estilo de vida que sustentará Ávalon mejora la existencia.</p>
                        </div>
                        <div className={styles.btnCommon} onClick={()=>{SelectMenu('desing')}}>
                            <p>Ver más</p><img src={Images.ArrowDown} alt={'arrow-down'}/>
                        </div>
                    </div>
                    <div className={`${styles.TextCommon} ${styles.amenidades}`}>
                        <div className={styles.title}>
                            <span></span>
                            <img src={Images.AmenidadesSvg} alt={'menu-amenidades'}/>
                        </div>
                        <div className={styles.text}>
                            <p>Las amenidades de Ávalon ofrecen oportunidades para actividades tanto recreativas como deportivas.</p>
                            <ul className={styles.listAme}>
                                <li>Alberca</li>
                                <li>Asoleadero</li>
                                <li>Sauna</li>
                                <li>Vapor</li>
                                <li>Gimnasio</li>
                                <li>Salón de Clases Mixtas</li>
                                <li>Ludoteca</li>
                                <li>Salón de Juegos</li>
                                <li>SPA & Jacuzzi</li>
                                <li>Snack Bar & Juice</li>
                            </ul>
                        </div>
                        <div className={styles.btnCommon} onClick={()=>{SelectMenu('amenidades')}}>
                            <p>Ver Amenidades</p><img src={Images.ArrowDown} alt={'arrow-down'}/>
                        </div>
                    </div>
                    <div className={`${styles.TextCommon} ${styles.ubicacion}`}>
                        <div className={styles.title}>
                            <span></span>
                            <img src={Images.UbicacionSvg} alt={'menu-ubicacion'}/>
                        </div>
                        <div className={styles.text}>
                            <p>Esta privilegiada demarcación cuenta con una muy buena ubicación pues está conectada con el resto de la ciudad por importantes arterias viales Circuito Interior, Avenida Insurgentes, División del Norte, Mixcoac, Avenida Universidad, Viaducto.</p>
                            <p>Con una demografía equilibrada y una alta presencia de oficinas, toda la colonia se establece como el verdadero centro de la ciudad.</p>
                        </div>
                        <div className={styles.btnCommon} onClick={()=>{SelectMenu('ubicacion')}}>
                            <p>Explora el entorno</p><img src={Images.ArrowDown} alt={'arrow-down'}/>
                        </div>
                    </div>                    

                </div>
            </div>

        </div>
    )
}
export default Top;