import React, { useState } from 'react';
import Slider from "react-slick";
import styles from './Desing.module.css';
import * as Images from '../Constant/Constant';

function Desing(props){

    const[dataShadow,setDataShadow] = useState({
        index: 0
    });

    let dataSlide = [{img:Images.SlideDesign,text:'Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.'},{img:Images.SlideDesign,text:'Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.'},{img:Images.SlideDesign,text:'Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.'},{img:Images.SlideDesign,text:'Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.'},{img:Images.SlideDesign,text:'Nos hemos encargado de seleccionar los mejores acabados para que tu espacio sea único, para que tengas la libertar de escoger entre nuestros dos tipos de acabados premium y de esta manera tu espacio se vea de una manera única. La arquitectura del Avalon inspirada en un diseño escandinavo único en la Ciudad de México.'}]
    let setting_slider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: current => { setActual(current) },        
    };
    function setActual(element) {
        setDataShadow({index:element})
    }
    return(
        <div ref={props.forwardRef} className={styles.wrapperDesign}>
            <div className={styles.wrapperTop}>
                <img src={Images.DisenioSvg} alt={'title-diseño'} />
                <span></span>
            </div>
            <div className={styles.wrapperSlider}>
                <Slider {...setting_slider}>
                    {
                        dataSlide.map((element,i)=>(
                            <div key={'slide-desing-'+i} className={styles.slide}>
                                <img src={element.img} alt={'slide-desing-'+i} />
                            </div>
                        ))
                    }
                </Slider>
                <div className={styles.shadow}>
                    <h1>{dataShadow.index+1}/{dataSlide.length}</h1>
                    <p>{dataSlide[dataShadow.index].text}</p>
                </div>
            </div>
        </div>
    )
}
export default Desing;

function NextArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${styles.icon_arrow_point_to} ${styles.icon_arrow_next}`;
    return (
        <div
            className={className + ' ' + classArrow}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${styles.icon_arrow_point_to} ${styles.icon_arrow_prev}`;
    return (
        <div
            className={className + ' ' + classArrow}
            onClick={onClick}
        />
    );
}