import logoAvalon from '../img/logo-avalon.svg';
import logoAvalonLine from '../img/logo-avalon-line.svg';
import arrow from '../img/arrow.svg';
import arrowdown from '../img/circle-arrow-down.svg';
import disenio from '../img/disenio.svg';
import amenidades from '../img/amenidades.svg';
import ubicacion from '../img/ubicacion.svg';
import contacto from '../img/contacto.svg';
import topDesktop from '../img/background-home.jpg';
import topMobile from '../img/mobile/background-home-mobile.jpg'
import led from '../img/iluminacion-led.jpg';
import touch from '../img/touch.jpg';
import gadgets from '../img/gadgets.jpg';
import imgInspiracion from '../img/inspiracion.jpg';
import imgAvance from '../img/avance-obra.png';
import imgAvanceMobile from '../img/mobile/avance-obra-mobile.png';
import imgAmenidades from '../img/amenidades.jpg';
import pinMapa from '../img/PinMapa.svg';
import imgBlur from '../img/blur-ubicacion.jpg';
import imgUbicacion from '../img/ubicacion.jpg';
import logoMier from '../img/logo-mier.svg';
import logoMonolith from '../img/logo-monolith.svg';
import logoHumboldt from '../img/logo-humboldt.svg';
//
import slideDesing from '../img/slide-disenio.jpg';
import slideGaleria from '../img/slide-amenidades.jpg';
import imgAsesor from '../img/foto-asesor.png';

export const LogoAvalon = logoAvalon;
export const LogoAvalonLine = logoAvalonLine;
export const Arrow = arrow;
export const ArrowDown = arrowdown;
export const DisenioSvg = disenio;
export const AmenidadesSvg = amenidades;
export const UbicacionSvg = ubicacion;
export const ContactoSvg = contacto;
export const TopDesktop = topDesktop;
export const TopMobile = topMobile;
export const LedImg = led;
export const TouchImg = touch;
export const GadgetImg = gadgets;
export const InspirationImg = imgInspiracion;
export const AvanceDesktop = imgAvance;
export const AvanceMobile = imgAvanceMobile;
export const AmenidadesImg = imgAmenidades;
export const mapPin = pinMapa;
export const BlurImg = imgBlur;
export const UbicacionImg = imgUbicacion;
export const LogoMier = logoMier;
export const LogoMonolith = logoMonolith;
export const LogoHumboldt = logoHumboldt;
export const GOOGLE_API_KEY = 'AIzaSyAW_ct6DuqszSMZo2GuBykyZTY2Bb4rQHY';
export const API_URL_QC = 'https://apiproduccionaz.quierocasa.com.mx:7007/';//Api Prod QC

//
export const SlideDesign = slideDesing;
export const SlideGaleria = slideGaleria;
export const FotoAsesor = imgAsesor;



