
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './Components/Home';
import "slick-carousel/slick/slick.css"; //css para sliders
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
      <Router>
        <React.Fragment>
          <Switch>
            <Route path="/" exact component={Home} />
          </Switch>
        </React.Fragment>
      </Router>
  );
}

export default App;
