import React from 'react';
import styles from './Inspiration.module.css';
import * as Images from '../Constant/Constant';

function Inspiration(){
    
    return(
        <div className={styles.wrapperInspiration}>
            <div className={styles.wrapperImg}>
                <div>
                    <img src={Images.InspirationImg} alt={'inspircion'} />
                </div>
            </div>
            <div className={styles.wrapperShadow}>
                <div>
                    <h1>Inspiración</h1>
                    <p>Ávalon inicialmente fue diseñado por <b>Enrique Norten</b>, y se fue adaptando a las necesidades actuales.</p>
                    <p>La inspiración fue el diseño escandinavo. Los países nórdicos tienen como objetivo crear espacios confortables, luminosos y modernos. El estilo nórdico se ha consolidado como corriente líder en lo que respecta a la Arquitectura, pero también en el diseño, gracias a una estética que se define por su sencillez y funcionalidad y que persigue la satisfacción y bienestar.</p>
                    <p>Se define como la fusión entre tecnología y sabiduría, entre modernidad y tradición.  Asi nace Ávalon como una respuesta a un nuevo de estilo de vida un oasis en la ciudad.</p>
                </div>
            </div>
        </div>
    )
}
export default Inspiration;