import React from 'react';
import styles from './Inovation.module.css';
import * as Images from '../Constant/Constant';

function Inovation() {

    return (
        <div className={styles.wrapperInovation}>
            <div className={styles.wrapperTop}>
                <h1>Innovación</h1>
                <h2>Ávalon ha integrado todos los avances tecnológicos más exclusivos en tus espacios para que puedas disfrutar de otra manera tu departamento, tu espacio, tu hogar.</h2>
            </div>
            <div className={styles.wrapperCards}>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.LedImg} alt={'iluminacion-led'} />
                        <p><span></span>Iluminación LED en tus espacios de interés</p>
                    </div>
                </div>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.TouchImg} alt={'touch'} />
                        <p><span></span>Controla tu casa desde un dispositivo touch</p>
                    </div>
                </div>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.GadgetImg} alt={'gadgets'} />
                        <p><span></span>Gadgets para tus dispositivos en tu espacio</p>
                    </div>
                </div>
            </div>
            <span></span>
        </div>
    )
}
export default Inovation;