import React from 'react';
import styles from './Amenidades.module.css';
import * as Images from '../Constant/Constant';

function Amenidades(props){
    
    return(
        <div ref={props.forwardRef} className={styles.wrapperAmenidades}>
            <div className={styles.wrapperTop}>
                <span></span>
                <img src={Images.AmenidadesSvg} alt={'title-diseño'} />
                <span></span>
            </div>
            <div className={styles.wrapperImg}>
                <div>
                    <img src={Images.AmenidadesImg} alt={'inspircion'} />
                </div>
            </div>
            <div className={styles.wrapperShadow}>
                <div>
                    <p>Avalon ofrece amplias oportunidades de llevar un estilo de vida equilibrado y saludable, que combine ejercicio y bienestar dentro de un lujoso condominio residencial.</p>
                    <ol>
                        <li>Alberca</li>
                        <li>Asoleadero</li>
                        <li>Sauna</li>
                        <li>Vapor</li>
                        <li>Gimnasio</li>
                        <li>Salón de Clases Mixtas</li>
                        <li>Ludoteca</li>
                        <li>Salón de Juegos</li>
                        <li>SPA & Jacuzzi</li>
                        <li>Snack Bar & Juice</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
export default Amenidades;