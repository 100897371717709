import React, { useEffect, useState } from 'react';
import styles from './Contacto.module.css';
import * as Images from '../Constant/Constant';
import { getAsesor,sendMailAsesor } from '../Services/ContactoService';

function Contacto(props){
    
    const[perfilAsesor,setPerfilAsesor] =useState({
        GUID: "",
        ID: "",
        Nombre_persona: "",
        Descripcion: "",
        Foto_colaborador: "",
        Correo: "",
        Telefono: "",
        Liga_Whatsapp: "",
        Anios: "",
        Suenios_cumplidos: "",
        Recomendacion1: "",
        Recomendacion2: "",
        Recomendacion3: "",
        Medalla1: "",
        Medalla2: "",
        Medalla3: "",
        Avalon: "",
        Monolith: ""
    });
    const[isSend,setIsSend] = useState(false);

    useEffect(()=>{
        InitAsesor();
    },[]);

    async function InitAsesor(){
        try {
            let data = await getAsesor().then(data => {
                return data;
            });

            setPerfilAsesor(data.data)

        } catch (e) {
            console.log(e);
        }
    }
    async function sendMail(data) {
        data.preventDefault();
        let duda = data.target[0].value;
        let mail = data.target[1].value;
        let dataMail = {
            "email_asesor": perfilAsesor.Correo,
            "name_asesor": perfilAsesor.Nombre_persona,
            "email_cliente": mail,
            "duda": duda
        }
        try {
            let data = await sendMailAsesor(dataMail).then(data => {
                return data;
            });
            setIsSend(data.estatus);
            
        } catch (e) {
            console.log(e);
        }
    }


    return(
        <div ref={props.forwardRef} className={styles.wrapperContacto}>
            <div className={styles.wrapperTop}>
                <img src={Images.ContactoSvg} alt={'title-contacto'} />
            </div>
            <div className={styles.wrapperBody}>
                <div className={styles.dataContacto}>
                    <h2>Contáctanos por medio de los siguientes teléfonos</h2>
                    <div className={styles.tel}>
                        <label>Teléfono directo Ávalon</label>
                        <p>55 5025 0409</p>
                    </div>

                    <p>Agenda tu cita en cualquier momento</p>
                </div>
                <div className={styles.formContacto}>
                    <h2>o uno de nuestros asesores te puede atender por medio de video llamada, WhatsApp o celular</h2>
                    <div className={styles.wrapperAsesor}>
                        <div className={styles.imgAsesor}>
                            <div>
                                <img src={perfilAsesor.Foto_colaborador} alt={'imagen-Asesor'} />
                            </div>
                        </div>
                        <div className={styles.dataAsesor}>
                            <div>
                                <div className={styles.TextAsesor}>
                                    <p className={styles.name}>{perfilAsesor.Nombre_persona}</p>
                                    <p className={styles.frase}>{perfilAsesor.Descripcion}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.numeroAsesor}>
                            <div>
                                <div className={styles.contactAsesor}>
                                    <p className={styles.telAsesor}>{perfilAsesor.Telefono}</p>
                                    <a href={perfilAsesor.Liga_Whatsapp} target={'_blank'} rel={"noreferrer"}>
                                        <p>Video llamada</p>
                                        <p>WhatsApp</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.wrapperForm}>
                        <p>Si desea información puede recibirla en su correo electrónico </p>
                        {(!isSend)&&
                        <form model="data" onSubmit={(data) => sendMail(data)} className={styles.form}>
                            <input className={styles.duda} model="data.duda" type="text"  placeholder="¿Tienes alguna duda?" />
                            <input className={styles.email} model="data.mailUser" type="email" placeholder="Ingresa tu email" />
                            <button className={styles.btnSubmit} type="submit">Enviar</button>
                        </form>
                        }
                        {
                            (isSend)&&
                            <div className={styles.gracias}>
                                <p>¡Muchas gracias por contactarme!</p>
                                <p>En breve estaré respondiendo tu correo. ¡Que tengas un excelente día!</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contacto;