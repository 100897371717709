import React, { useEffect, useState } from 'react';
import styles from './AvanceObra.module.css';
import * as Images from '../Constant/Constant';

function AvanceObra() {
    const[imgAvance,setimgAvance]= useState({
        img: Images.AvanceDesktop
    })

    useEffect(()=>{

        function handleResize() {
            if(window.innerWidth > 767){
                setimgAvance({img:Images.AvanceDesktop})
            }else{
                setimgAvance({img:Images.AvanceMobile})
            }
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        
        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    },[]);
    return (
        <div className={styles.wrapperAvanceObra}>
            <img src={imgAvance.img} alt={'avance-de-obra'}/>
        </div>
    )
}
export default AvanceObra;