import React from 'react';
import styles from './Header.module.css';
import * as Images from '../Constant/Constant';

function Header({onSelectMenu}){
    
    function SelectMenu(value){
        onSelectMenu(value)
    }


    return(
        <div className={styles.wrapperHeader}>
            <div className={styles.wrapperLogo}>
                <img src={Images.LogoAvalonLine} onClick={()=>{SelectMenu('top')}} alt={'logo-avalon-line'} />
            </div>
            <div className={styles.wrapperMenus}>
                <div className={styles.wrapperDisenio}>
                    <img src={Images.DisenioSvg} onClick={()=>{SelectMenu('desing')}} alt={'menu-disenio'} />
                </div>
                <div className={styles.wrapperAmenidades}>
                    <img src={Images.AmenidadesSvg} onClick={()=>{SelectMenu('amenidades')}} alt={'menu-amenidades'} />
                </div>
                <div className={styles.wrapperUbicacion}>
                    <img src={Images.UbicacionSvg} onClick={()=>{SelectMenu('ubicacion')}} alt={'menu-ubicacion'} />
                </div>
            </div>
            <div className={styles.wrapperContact}>
                <img src={Images.ContactoSvg} onClick={()=>{SelectMenu('contacto')}} alt={'menu-contacto'} />
            </div>
        </div>
    )
}
export default Header;