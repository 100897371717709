import React, { useRef, useState } from 'react';
import Header from './Header';
import SplashScreen from './SplashScreen';
import styles from './Home.module.css';
import Top from './Top';
import Desing from './Desing';
import Inspiration from './Inspiration';
import Inovation from './Inovation';
import Vista360 from './Vista360';
import AvanceObra from './AvanceObra';
import Amenidades from './Amenidades';
import Galeria from './Galeria';
import Ubicacion from './Ubicacion';
import Footer from './Footer';
import Contacto from './Contacto';

function Home(){

    const[splashVisible,setSlashVisible]= useState(true)
    const refDesing = useRef(null);
    const refAmenidades = useRef(null);
    const refUbicacion = useRef(null);
    const refContacto = useRef(null);

    function ToogleSplash(value){
        setSlashVisible(value)
    }
    function scrollTo(value){

        let scrollY = 0;
        let _desing = refDesing.current.getBoundingClientRect().top;
        let _amenidades = refAmenidades.current.getBoundingClientRect().top;
        let _ubicacion = refUbicacion.current.getBoundingClientRect().top;
        let _contacto = refContacto.current.getBoundingClientRect().top;

        let fix = window.pageYOffset - 35;
        switch(value){
            case 'desing':
                scrollY = _desing - 40 + fix;
            break;
            case 'amenidades':
                scrollY = _amenidades + fix;
            break;
            case 'ubicacion':
                scrollY = _ubicacion + fix;
            break;
            case 'contacto':
                scrollY = _contacto + fix;
            break;
            default:
                scrollY=0;
            break;
        }
        window.scrollTo(0,scrollY)

    }

    return(
        <div>
            {splashVisible&&
                <SplashScreen onToogle={(value)=> ToogleSplash(value)} />
            }
            {!splashVisible&&
                <div className={styles.wrapperSections}>
                    <Header onSelectMenu={(value)=> scrollTo(value)} />
                    <Top onSelectMenu={(value)=> scrollTo(value)}/>
                    <div className={styles.wrapperBody}>
                        <Desing forwardRef={refDesing}/>
                        <Inovation />
                        <Inspiration />
                        <Vista360 />
                        <AvanceObra />
                        <Amenidades forwardRef={refAmenidades}/>
                        <Galeria />
                        <Ubicacion forwardRef={refUbicacion}/>
                        <Contacto forwardRef={refContacto}/>
                        <Footer/>
                    </div>                    
                </div>
            }
        </div>
    );
}

export default Home;