import React from 'react';
import styles from './Ubicacion.module.css';
import * as Images from '../Constant/Constant';
import WrapperMap from './WrapperMap';

function Ubicacion(props){
    
    return(
        <div ref={props.forwardRef} className={styles.wrapperUbicacion}>
            <div className={styles.wrapperTop}>
                <img src={Images.UbicacionSvg} alt={'title-diseño'} />
            </div>
            <div className={styles.wrapperMap}>
                <div>
                    <WrapperMap />
                </div>
            </div>
            <div className={styles.wrapperShadow}>
                <div>
                    <h1>POPOCATÉPETL NO 526</h1>
                    <h2>Col. Xoco, Del. Benito Juárez, C.P. 03330, Ciudad de México</h2>
                    <p className={styles.first}>Esta privilegiada demarcación cuenta con una muy buena ubicación pues está conectada con el resto de la ciudad por importantes arterias viales como Circuito Interior, Avenida Insurgentes, División del Norte, Mixcoac, Avenida Universidad, Viaducto, Barranca del Muerto y los Ejes Poniente y Sur.</p>
                    <p>Con una demografía equilibrada y una alta presencia de oficinas, toda la colonia se establece como el verdadero centro de la ciudad. Y es sin duda un punto de encuentro en constante evolución.</p>
                </div>
            </div>
            <div className={styles.imgBlur}>
                <img src={Images.BlurImg} alt={'luces-blur'}/>
            </div>
            <div className={styles.imgUbicacion}>
                <img src={Images.UbicacionImg} alt={'vista-nivel-32'}/>
            </div>
        </div>
    )
}
export default Ubicacion;