import React from 'react';
import styles from './Footer.module.css';
import * as Images from '../Constant/Constant';

function Footer() {

    return (
        <div className={styles.wrapperFooter}>
            <div className={styles.wrapperTop}>
                <h1>Conoce otros  desarrollos de nuestra marca Agatha by QC </h1>
            </div>
            <div className={styles.wrapperCards}>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.LogoMier} alt={'logo-mier-y-pesado'} />
                    </div>
                </div>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.LogoMonolith} alt={'logo-monolith'} />
                    </div>
                </div>
                <div className={styles.commonCard}>
                    <div>
                        <img src={Images.LogoHumboldt} alt={'logo-humboldt'} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;